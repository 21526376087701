import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import {
  AiOutlineTeam,
  AiOutlineUserAdd,
  AiFillPlusCircle,
  AiTwotoneLock,
  AiTwotoneUnlock,
  AiFillDelete,
  AiOutlineInfoCircle,
} from "react-icons/ai";

import { logobaseurl } from "../Services/Http-Common";
import ReactCountryFlag from "react-country-flag";
import PublisherNotes from "./PublisherNotes";

import Navbar from "./Navigationbar";
import {
  Grid,
  Modal,
  Dropdown,
  Text,
  Input,
  Spacer,
  Popover,
  Button,
  Card,
  Tooltip,
} from "@nextui-org/react";
import advertiserService from "../Services/advertiserService";
import publisherService from "../Services/publisherService";

function Publisher() {
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let mothername = queryParams.get("publishermothername");
  // let mothername = location?.sta?.publishermothername;
  // const allnetwork = location?.state?.allnetwork[0].allnetwork;
  // let advertiserlist = [];
  const [publishermothername, setPublishermothername] = useState(mothername);
  const [temppublishermothername, setTempPublishermothername] =
    useState(publishermothername);
  const [publishercontact, SetPublishercontact] = useState([]);
  const [publishernotes, setPublisherNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [connectedpublisher, setConnectedPublisher] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [advertiserlist, setAdvertiserlist] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState([]);
  const [allpublishertype, setAllpublisherType] = useState([]);
  const allmotherpublishertype = [
    "Subnetwork",
    "Cashback/Loyalty",
    "Content",
    "Social Media",
    "CSS",
    "Search",
    "Display",
    "Coupon/Rebate",
    "Price-Comparison/Shopping Engine",
    "Retargeting/Prospecting",
    "Onsite/Tech/Bounce",
    "Internal",
  ];
  const [selectedpublishermothertype, setSelectedpublishermothertype] =
    useState();
  const [visible, setVisible] = useState(false);
  const [networkvisible, setNetworkVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const [allnetwork, setAllNetwork] = useState();
  let allnetwork = ["awin", "retailads", "cj", "adtraction"];

  useEffect(() => {
    publisherService
      .getconnectedpublisher(publishermothername, [])
      .then(async (response) => {
        if (response.status === 200) {
          setConnectedPublisher(response.data.connectedpublisherarray);
          setSelectedpublishermothertype(
            response.data.connectedpublisherarray[0].publishermothertype
          );
          SetPublishercontact(response.data.publishercontact);
          setAdvertiserlist(response.data.advertiserlist);
        }
      });

    publisherService
      .getpublishernote(publishermothername)
      .then(async (response) => {
        if (response.status === 200) {
          setPublisherNotes(response.data.publishernotes);
        }
      });

    publisherService.getallpublishertype().then(async (response) => {
      if (response.status === 200) {
        setAllpublisherType(response.data.allpublishertype);
      }
    });
  }, [history, publishermothername]);

  const tempAdvertiserArray = ["all"];

  advertiserlist.forEach((a) => {
    tempAdvertiserArray.push(JSON.stringify(a.advertiserId));
  });

  const selectedAvertiserlist = React.useMemo(
    () => Array.from(selectedAdvertiser).concat(),
    [selectedAdvertiser]
  );

  // const handleselectedadvertiser = async (value) => {
  //   let tempadvertiserlistarray = [];
  //   if (selectedAvertiserlist.includes(value)) {
  //     tempadvertiserlistarray = [];
  //   } else {
  //     tempadvertiserlistarray = tempAdvertiserArray;
  //   }
  //   setSelectedAdvertiser(tempadvertiserlistarray);
  //   console.log(selectedAdvertiser);
  // };

  const getDataFromPublisherNotes = (updatednote) => {
    const match = publishernotes.some((n) => n._id === updatednote._id);
    if (!match) {
      setPublisherNotes([updatednote, ...publishernotes]);
    } else {
      const idxObj = publishernotes.findIndex((object) => {
        return object._id === updatednote._id;
      });
      if (idxObj <= -1) {
        setPublisherNotes([updatednote, ...publishernotes]);
      } else {
        publishernotes.splice(idxObj, 1);
        publishernotes.splice(idxObj, 0, updatednote);
        setPublisherNotes([...publishernotes]);
      }
    }
  };

  const getpublisherstatitics = async () => {
    await publisherService
      .getconnectedpublisher(publishermothername, selectedAvertiserlist)
      .then(async (response) => {
        if (response.status === 200) {
          setConnectedPublisher(response.data.connectedpublisherarray);
        }
      });
  };

  const handler = () => setVisible(true);
  const networkhandler = () => setNetworkVisible(true);

  const closeHandler = () => {
    setVisible(false);
  };
  const networkcloseHandler = () => {
    setNetworkVisible(false);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = async () => {
    await publisherService
      .editpublishermothername(publishermothername, temppublishermothername)
      .then((response) => {
        if (response.status === 200) {
          setPublishermothername(temppublishermothername);
          setTempPublishermothername(temppublishermothername);
        }
      });
    setIsEditing(false);
  };
  const handleSearch = async () => {
    try {
      const response = await publisherService.searchpublisher(searchQuery);
      setSearchResults(response.data.relatedpublishers);
    } catch (error) {
      localStorage.removeItem("TOU");
      return history.push("/Unauthorised");
    }
  };

  const handleAddNewContactInput = () => {
    if (publishercontact.length < 1) {
      SetPublishercontact([{ name: "", email: "", telephone: "" }]);
    } else {
      SetPublishercontact([
        ...publishercontact,
        { name: "", email: "", telephone: "" },
      ]);
    }
  };

  const handlepublishercontactarray = (index, value, type) => {
    if (type === "name") {
      publishercontact[index].name = value;
    }
    if (type === "email") {
      publishercontact[index].email = value;
    }
    if (type === "telephone") {
      publishercontact[index].telephone = value;
    }
  };

  const handleFilterChange = (e) => {
    setSelectedNetwork(e.target.value);
  };

  const savecontacthandler = async (publishercontact) => {
    const filteredContact = publishercontact.filter(
      (contact) => contact.name && contact.email && contact.telephone
    );
    closeHandler();
    SetPublishercontact(filteredContact);
    await publisherService
      .insertcontactperson(publishermothername, filteredContact)
      .then((response) => {
        if (response.status !== 200) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((error) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const connectpublisher = async (pub) => {
    await publisherService
      .connectpublisher(pub._id, publishermothername)
      .then(async (response) => {
        if (response.status === 200) {
          if (connectedpublisher === null) {
            setConnectedPublisher([
              {
                _id: pub._id,
                publisherId: pub.publisherId,
                publisherName: pub.publisherName,
                network: pub.network,
              },
            ]);
          } else {
            setConnectedPublisher([
              ...connectedpublisher,
              {
                _id: pub._id,
                publisherId: pub.publisherId,
                publisherName: pub.publisherName,
                network: pub.network,
              },
            ]);
          }
          await publisherService
            .getconnectedpublisher(publishermothername, [])
            .then(async (response) => {
              if (response.status === 200) {
                setConnectedPublisher(response.data.connectedpublisherarray);
              }
            });
        }
      })
      .catch((error) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const removepublisher = async (pub_id) => {
    await publisherService
      .removepublisher(pub_id)
      .then((response) => {
        if (response.status === 200) {
          var newArray = connectedpublisher.filter(function (obj) {
            return obj._id !== pub_id;
          });
          setConnectedPublisher(newArray);
        }
      })
      .catch((error) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const handlchangepublishertype = async (pub_id, pubtype, mothertype) => {
    await publisherService
      .editpublishertype(pub_id, pubtype)
      .then((response) => {
        if (response.status === 200) {
          let updatedArray = connectedpublisher.map((obj) => {
            if (obj._id === pub_id) {
              return { ...obj, type: pubtype };
            } else {
              return obj;
            }
          });

          setConnectedPublisher(updatedArray);
        }
      })
      .catch((error) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  return (
    <div style={{ minHeight: "80rem" }}>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1%",
        }}
      >
        <h1 onDoubleClick={handleDoubleClick} onBlur={handleBlur}>
          {isEditing ? (
            <input
              type="text"
              value={temppublishermothername}
              autoFocus
              onChange={(e) => setTempPublishermothername(e.target.value)}
            />
          ) : (
            publishermothername
          )}
          <Grid xl={9.5} sm={9.5}>
            <Popover placement="right-bottom">
              <Popover.Trigger>
                <Button color={"white"} css={{ justifyContent: "left" }}>
                  {selectedpublishermothertype && selectedpublishermothertype
                    ? selectedpublishermothertype
                    : "Select Type"}
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                {allmotherpublishertype.map((mothertype, index) => {
                  return (
                    <div
                      tabIndex={index}
                      style={{
                        cursor: "cell",
                        backgroundColor:
                          mothertype === selectedpublishermothertype
                            ? "lightgreen"
                            : "white",
                      }}
                      className="px-1 py-2"
                      onClick={(e) => {
                        publisherService.editpublishermothertype(
                          publishermothername,
                          mothertype
                        );
                        setSelectedpublishermothertype(mothertype);
                      }}
                    >
                      {mothertype !== null ? mothertype : "-"}
                    </div>
                  );
                })}
              </Popover.Content>
            </Popover>
          </Grid>
        </h1>
      </div>
      <Text
        h3
        css={{
          textGradient: "45deg, $green600 -20%, $pink600 50%",
        }}
      >
        Contact Persons
        <span>
          {" "}
          <AiOutlineTeam onClick={(e) => handler()} />
        </span>
      </Text>
      {publishercontact?.length > 0 ? (
        <ul>
          {publishercontact.map((contact, index) => (
            <li key={index}>
              <Popover placement="right">
                <Popover.Trigger>
                  <Button auto bordered>
                    {contact.name}
                  </Button>
                </Popover.Trigger>
                <Popover.Content>
                  <Text css={{ p: "$2" }}>{contact.email}</Text>
                  <Text css={{ p: "$2" }}>{contact.telephone}</Text>
                </Popover.Content>
              </Popover>
            </li>
          ))}
        </ul>
      ) : (
        <ul> Contact not found !!</ul>
      )}

      <Modal
        closeButton
        preventClose
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          {publishercontact?.length < 1 ? (
            <Text id="modal-title" size={18}>
              No contact found.
            </Text>
          ) : (
            <></>
          )}
        </Modal.Header>
        <Modal.Body>
          {publishercontact.map((contact, index) => (
            <Grid>
              <Text color="primary" size="$xl">
                Contact Person : {index + 1}
              </Text>
              <Input
                tabIndex={index}
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                placeholder="Name"
                value={contact.name}
                onChange={(e) =>
                  handlepublishercontactarray(index, e.target.value, "name")
                }
              />
              <Spacer y={0.5} />
              <Input
                tabIndex={index}
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                placeholder="Email"
                value={contact.email}
                onChange={(e) =>
                  handlepublishercontactarray(index, e.target.value, "email")
                }
              />
              <Spacer y={0.5} />

              <Input
                tabIndex={index}
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                type="number"
                placeholder="Telephone"
                value={contact.telephone}
                onChange={(e) =>
                  handlepublishercontactarray(
                    index,
                    e.target.value,
                    "telephone"
                  )
                }
              />
            </Grid>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button auto color="secondary" onPress={handleAddNewContactInput}>
            <AiOutlineUserAdd />
          </Button>

          <Button auto onPress={(e) => savecontacthandler(publishercontact)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <Grid.Container justify="left">
          <Grid justify="left">
            <Text
              h3
              css={{
                textGradient: "45deg, $green600 -20%, $blue600 50%",
              }}
            >
              Connected Networks <AiFillPlusCircle onClick={networkhandler} />
            </Text>
          </Grid>
          <Grid xl={9.5} sm={9.5} justify="right">
            <Dropdown css={{ width: "25rem" }}>
              <Dropdown.Button flat color="primary" css={{ tt: "capitalize" }}>
                Filter By Advertisers
              </Dropdown.Button>
              <Dropdown.Menu
                aria-label="Multiple selection actions"
                css={{ maxWidth: "fit-content" }}
                color="success"
                selectionMode="multiple"
                selectedKeys={selectedAdvertiser}
                onSelectionChange={setSelectedAdvertiser}
                onAction={(value) => {
                  if (selectedAvertiserlist.includes(value.toString())) {
                    selectedAvertiserlist.pop(value.toString());
                  } else {
                    selectedAvertiserlist.push(value.toString());
                  }
                  getpublisherstatitics();
                }}
                variant="shadow"
              >
                {advertiserlist.map((ele, index) => {
                  return (
                    <Dropdown.Item
                      key={ele.advertiserId}
                      css={{ maxWidth: "25rem" }}
                    >
                      {ele.advertiserName}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Grid>
        </Grid.Container>

        <div>
          {allnetwork.map((network, index) => {
            return (
              <div>
                <Text h3>{network.toUpperCase()}</Text>

                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Type</th>
                      <th>Publisher Id</th>
                      <th>Publisher Name</th>
                      <th>LAST WK</th>
                      <th>3 WK AVG</th>
                      <th>6 WK AVG</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {connectedpublisher.map((connectedpublisher, index) => {
                      return connectedpublisher.network === network ? (
                        // <li class="list-group-item" tabIndex={index}>
                        // <AiFillDelete
                        //   size="1.2rem"
                        //   color="red"
                        //   onClick={(e) => removepublisher(connectedpublisher._id)}
                        // />{" "}
                        //   {connectedpublisher.publisherName}
                        // </li>
                        <tr tabIndex={index}>
                          <td>
                            {" "}
                            <AiFillDelete
                              size="1.2rem"
                              color="red"
                              onClick={(e) =>
                                removepublisher(connectedpublisher._id)
                              }
                            />
                          </td>
                          <td>
                            <Popover placement="right-bottom">
                              <Popover.Trigger>
                                <Button
                                  color={"white"}
                                  css={{ justifyContent: "left" }}
                                >
                                  {connectedpublisher?.type !== null
                                    ? connectedpublisher?.type
                                    : "--"}
                                </Button>
                              </Popover.Trigger>
                              <Popover.Content>
                                {allpublishertype.map((pub, index) => {
                                  return (
                                    <div
                                      tabIndex={index}
                                      style={{
                                        cursor: "cell",
                                        backgroundColor:
                                          pub === connectedpublisher?.type
                                            ? "lightgreen"
                                            : "white",
                                      }}
                                      className="px-1 py-2"
                                      onClick={(e) =>
                                        handlchangepublishertype(
                                          connectedpublisher._id,
                                          pub
                                        )
                                      }
                                    >
                                      {pub !== null ? pub : "-"}
                                    </div>
                                  );
                                })}
                              </Popover.Content>
                            </Popover>
                          </td>
                          <td>{connectedpublisher.publisherId}</td>
                          <td>
                            <Tooltip
                              placement="right"
                              showArrow={true}
                              content={
                                <ConnectedAdvertiser
                                  pub_Id={connectedpublisher._id}
                                />
                              }
                            >
                              {connectedpublisher.publisherName}{" "}
                            </Tooltip>
                          </td>
                          <td>{connectedpublisher.lastweeksale}</td>
                          <td>{connectedpublisher.lastthreeweeksale}</td>
                          <td>{connectedpublisher.lastsixweeksale}</td>
                          <td>{connectedpublisher.count}</td>
                        </tr>
                      ) : (
                        <></>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
        <Modal
          closeButton
          animated={true}
          width={"50rem"}
          blur
          aria-labelledby="modal-title"
          open={networkvisible}
          onClose={networkcloseHandler}
        >
          <Modal.Header>
            <Text b size={18}>
              Network Connection
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleSearch}>
                Search
              </button>
            </div>
            <select
              className="form-control"
              value={selectedNetwork}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {allnetwork.map((network, index) => (
                <option key={index} value={network}>
                  {network}
                </option>
              ))}
            </select>
            {searchResults.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Index</th>
                    <th scope="col">Network</th>
                    <th scope="col">Name</th>
                    <th scope="col">Connect</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((publisher, index) => {
                    if (
                      selectedNetwork === "" ||
                      publisher.network === selectedNetwork
                    ) {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{publisher.network.toUpperCase()}</td>
                          <td
                            onClick={(e) => {
                              setPublishermothername(
                                publisher.publishermothername
                              );
                              networkcloseHandler();
                            }}
                          >
                            {publisher.publisherName}
                          </td>
                          <td>
                            {publisher.isconnected ||
                            connectedpublisher.some(
                              (x) => x._id === publisher._id
                            ) ? (
                              <AiTwotoneLock
                                size="1.5rem"
                                color="red"
                                aria-disabled
                              />
                            ) : (
                              <AiTwotoneUnlock
                                size="1.5rem"
                                color="green"
                                onClick={(e) => connectpublisher(publisher)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            ) : (
              <></>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <Grid.Container gap={2} justify="center">
        {advertiserlist.map((adv, index) => {
          return (
            <Grid xl={1.5} sm={2.5} justify="center" key={index}>
              <Card>
                <Card.Header>
                  <Grid xl={11} sm={11} justify="center">
                    <ReactCountryFlag
                      countryCode={adv.advertiserName.split(" ").pop()}
                      svg
                      style={{
                        width: "2em",
                        height: "2em",
                      }}
                    />
                    <Spacer y={2}></Spacer>
                    {adv.logourl ? (
                      <img src={logobaseurl + adv.logourl} alt="logo img" />
                    ) : (
                      "No Logo"
                    )}
                  </Grid>
                </Card.Header>
              </Card>
            </Grid>
          );
        })}
      </Grid.Container>

      {/* Publisher Notes function ------------------------------------------------------------------------- */}
      <Grid xs={5} xl={5} sm={5}>
        <PublisherNotes
          advertiserArray={advertiserlist}
          publishermothername={publishermothername}
          getDataFromPublisherNotes={getDataFromPublisherNotes}
        />
      </Grid>
      <Card css={{ marginTop: "$4" }}>
        <Grid css={{ p: "$4" }}>
          {publishernotes.map((note, index) => {
            return (
              <div>
                <Grid>
                  {note.user} ::{" "}
                  {moment(note.created_time).format("DD-MM-YYYY")}
                </Grid>
                <Grid.Container gap={2} justify="left">
                  <Grid xs={9.6} xl={11.1} sm={10.5} justify="left">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      value={note.notedetail}
                      disabled
                    ></textarea>
                  </Grid>
                  <Grid>
                    <Tooltip
                      placement="left"
                      showArrow={true}
                      content={
                        <div>
                          {advertiserlist
                            .filter((obj) =>
                              note.connectedAdvertiser.includes(obj._id)
                            )
                            .map((ad, index) => {
                              return (
                                <li tabIndex={index}>{ad.advertiserName}</li>
                              );
                            })}
                        </div>
                      }
                    >
                      <AiOutlineInfoCircle size={25} />
                    </Tooltip>
                  </Grid>
                  <Grid xs={0.5} xl={0.5} sm={1} justify="right">
                    <PublisherNotes
                      advertiserArray={advertiserlist}
                      publishermothername={publishermothername}
                      publishernoteId={note._id}
                      notedetail={note.notedetail}
                      connectedAdvertiser={note.connectedAdvertiser}
                      getDataFromPublisherNotes={getDataFromPublisherNotes}
                    />
                  </Grid>
                </Grid.Container>
              </div>
            );
          })}
        </Grid>
      </Card>
    </div>
  );
}

function ConnectedAdvertiser(props) {
  const pub_Id = props.pub_Id;

  const [advertisers, setAdvertisers] = useState([]);

  useEffect(() => {
    advertiserService.getAdvertiser(pub_Id).then(async (response) => {
      if (response.status === 200) {
        setAdvertisers(response.data.data);
      }
    });
  }, [pub_Id]);

  return (
    <div>
      {advertisers?.length > 0 ? (
        <div style={{ height: "15rem", overflowY: "auto" }}>
          <table
            className="table"
            style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <thead
              style={{
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 2,
              }}
            >
              <tr>
                <th>Advertiser Name</th>
                <th>LAST WK</th>
                <th>3 WK AVG</th>
                <th>6 WK AVG</th>
                <th>TOTAL</th>
                <th>RATIO</th>
              </tr>
            </thead>
            <tbody>
              {advertisers.map((adv, index) => (
                <tr key={index}>
                  <td>{adv.advertiserName}</td>
                  <td>{adv.lastweeksale}</td>
                  <td>{adv.lastthreeweeksale}</td>
                  <td>{adv.lastsixweeksale}</td>
                  <td>{adv.count}</td>
                  <td>{parseFloat(adv.ratio).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div class="spinner-border"></div>
      )}
    </div>
  );
}


export default Publisher;
